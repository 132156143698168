import type { ResponseError } from 'umi-request';
import { extend } from 'umi-request';
import { getToken } from './common';
import { setTokenCookie } from './serverRequest';
import { notification } from 'antd';
import { ObjectKeyToLine } from './paramParse';
import { getRequestEnvUrl } from './env';

const codeMessage: Record<string, string> = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '登录失效',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '验证码错误',
  410: '请求的资源被永久删除，且不会再得到的。',
  413: '上传的文件太大',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
  440: '请求频繁',
};

const notThrowErrorMsgUrl = ['sso/get_platform_id'];
export const clearInfo = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('__user_info__');
    window.localStorage.removeItem('_token');
    setTokenCookie('');
  }
};
// 统一异常处理
const errorHandler = async (error: ResponseError<any>): Promise<Response> => {
  const { response, request } = error;
  const suppressError = request?.options?.context?.suppressError;
  if (response?.status) {
    response.clone();
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    if (status === 401) {
      clearInfo();
    } else {
      if (!suppressError) {
        notification.error({
          message: `请求错误 ${status}: ${url}`,
          description: errorText,
        });
      }
    }
  } else if (!response) {
    if (notThrowErrorMsgUrl.find((url) => request.url.includes(url))) {
      throw error;
    }

    if (!suppressError)
      notification.error({
        description: '您的网络发生异常，无法连接服务器',
        message: '网络异常',
      });
  }
  throw error;
};

export const request = extend({
  errorHandler,
  credentials: 'same-origin',
  mode: 'cors',
  headers: {
    token: '',
    'Cache-Control': 'no-cache',
    pragma: 'no-cache',
  },
  prefix: getRequestEnvUrl(),
});
export default request;

const unUseTokenHostList = ['pip-api.ccint.com', 'textin-studio.ai.intsig.net'];
const getHostName = (url: string) => url.split('/')[2];

// 拦截器
request.interceptors.request.use((url, options: any) => {
  if (!options.isServer) {
    const token = getToken(options.tokenKey);
    options.headers.token = token ? token : '';
  }
  if (unUseTokenHostList.includes(getHostName(url))) {
    delete options.headers;
  }

  if (options.deleteToken === true) {
    delete options.headers.token;
  }

  const { data, isServer } = options;
  if (
    data &&
    (isServer || (!(data instanceof Blob) && !(data instanceof File))) &&
    typeof data === 'object'
  ) {
    const params = ObjectKeyToLine(data);
    Object.assign(options, {
      data: params,
    });
  }

  return {
    url,
    options,
  };
});

export interface IResponse {
  code: number;
  msg: string;
}

export interface IPaginationReq {
  pageSize: number;
  pageNum: number;
}

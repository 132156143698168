import type { IResponse } from '@/utils';
import request from '@/utils/request';

export interface ISettingRes extends IResponse {
  data: {
    key: string;
    value: string; //序列化json
  };
}

// 获取配置
export async function getSettingService(key: string) {
  return request<ISettingRes>(`/setting/get?key=${key}`, {
    context: { suppressError: true },
  });
}

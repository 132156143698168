import { getSettingService } from '@/service/setting';
import { getImgUrlAndFallback } from '@/utils';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

export type TextinComSetting = {
  commmunity_qr_code?: string;
  commmunity_qr_code_url?: string;
};

export type TextinImageSetting = {
  commmunity_qr_code?: string;
  commmunity_qr_code_url?: string;
};

export type TextinIntfinqSetting = {
  commmunity_qr_code?: string;
  commmunity_qr_code_url?: string;
};

function useStore() {
  const [textinComSettings, setTexinComSettings] = useState<TextinComSetting>({});
  const [TextinImageSetting, setTexinImageSettings] = useState<TextinImageSetting>({});
  const [textinIntfinqSettings, setTextinIntfinqSettings] = useState<TextinIntfinqSetting>({});

  const loadSettings = async (key: string, onSuccess?: (data: any) => void) => {
    try {
      const res = await getSettingService(key);
      if (res.code === 200) {
        onSuccess?.(transformApiData(JSON.parse(res.data.value)));
      } else {
        console.error(res.msg);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const transformApiData = (data: any) => {
    const { commmunity_qr_code, ...rest } = data || {};
    return {
      ...rest,
      commmunity_qr_code_url: commmunity_qr_code && getImgUrlAndFallback(commmunity_qr_code),
    };
  };

  const loadTextinComSettings = () => loadSettings('textin_com', setTexinComSettings);
  const loadTextinImageSettings = () => loadSettings('textin_image', setTexinImageSettings);
  const loadTextIntfingSettings = () => loadSettings('textin_intfinq', setTextinIntfinqSettings);

  const loadTextinSettings = () => {
    loadTextinComSettings();
    loadTextinImageSettings();
    loadTextIntfingSettings();
  };

  useEffect(() => {
    loadTextinSettings();
  }, []);
  return {
    textinComSettings,
    loadTextinComSettings,
    TextinImageSetting,
    loadTextinImageSettings,
    textinIntfinqSettings,
    loadTextIntfingSettings,
    loadTextinSettings,
  };
}

export const settingModelContainer = createContainer(useStore);

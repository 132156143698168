import { useEffect } from 'react';
import nookies from 'nookies';
import { useRouter } from 'next/router';
import { message } from 'antd';
import serverRequest from '@/utils/serverRequest';
import { env, getEnv, getToken, isBrowser, request } from '@/utils';
import { track } from '@/utils/track';
import { contract_service_2 } from '@/containers/Product/dataSource/contract';

export const cookieKey = 'x_ti_app_id';
export const cookieTokenKey = 'x_ti_textin_token';

const cookieOption = {
  maxAge: 7 * 24 * 60 * 60,
  path: '/',
  domain: 'textin.com',
};

const to_base64 = (val: string) => {
  if (isBrowser()) {
    return window.btoa(val);
  } else {
    return Buffer.from(val).toString('base64');
  }
};

export const setUserCookie = (ctx, userRes) => {
  if (userRes?.code === 200) {
    const { app_id, secret_code } = userRes.data;
    const val = to_base64(`${app_id}${secret_code}`);
    let envPrefix = '';
    if (ctx) {
      const { host } = ctx.req.headers;
      if (getEnv(host) === env.TEST) {
        envPrefix = 'test_';
      }
    }
    nookies.set(ctx, envPrefix + cookieKey, val, cookieOption);
    const token = getToken() || '';
    if (token) {
      nookies.set(ctx, envPrefix + cookieTokenKey, getToken() || '', cookieOption);
    }
  } else {
    throw new Error(userRes.msg);
  }
};

const getFreePackage = (robotRes, packageRes) => {
  if (robotRes.code === 200) {
    const isFree = packageRes.data?.find((i) => i.is_free);
    if (!robotRes.data.is_owned && isFree) {
      const data = {
        id: isFree?.id,
        service: robotRes.data.service,
        package_count: 1,
        t_coin: isFree?.t_coin || 0,
        limit_count: isFree?.limit_count || 0,
        t_coin_total: 0,
        limit_count_total: 0,
        auto_purchase_free_package: 1,
      };
      return data;
    }
  } else {
    throw new Error(robotRes.msg);
  }
};

export default function ActivateContract() {
  const { query } = useRouter();

  useEffect(() => {
    (async () => {
      const { service, redirect } = query;
      if (!service || !redirect) return;
      try {
        const [robotRes, packageRes, robot2Res, package2Res, userRes] = await Promise.all([
          request.get(`/robot/detail`, { params: { service } }),
          request.get(`/user/robot/package/${service}`),
          request.get(`/robot/detail`, { params: { service: contract_service_2 } }),
          request.get(`/user/robot/package/${contract_service_2}`),
          request.post(`/service/user/info`, { data: { with_app: 1 } }),
        ]);

        setUserCookie(null, userRes);

        const data = getFreePackage(robotRes, packageRes);
        if (data) {
          const res2 = await request.post('/robot/package_template/purchase', {
            data,
          });
          if (res2.code !== 200) {
            throw new Error(res2.msg);
          }
        }

        const data2 = getFreePackage(robot2Res, package2Res);
        if (data2) {
          const res2 = await request.post('/robot/package_template/purchase', {
            data: data2,
          });
          if (res2.code !== 200) {
            throw new Error(res2.msg);
          }
        }

        if (typeof redirect === 'string') {
          window.open(decodeURIComponent(redirect), '_self');
        }
      } catch (e: any) {
        console.log('合同比对开通报错', e);
        message.error('系统异常，请稍候再试');
        track({ name: '合同比对开通报错', keyword: e });
      }
    })();
  }, [query]);

  return <div />;
}

const Logger = (...msg) => {
  console.log(`[${new Date().toLocaleString()}]`, ...msg);
};

// 合同比对自动开通
export async function getServerSideProps(ctx) {
  const { service, redirect } = ctx.query;
  try {
    const [robotRes, packageRes, robot2Res, package2Res, userRes] = await Promise.all([
      serverRequest(ctx, true).get(`/robot/detail`, { params: { service } }),
      serverRequest(ctx, true).get(`/user/robot/package/${service}`),
      serverRequest(ctx, true).get(`/robot/detail`, { params: { service: contract_service_2 } }),
      serverRequest(ctx, true).get(`/user/robot/package/${contract_service_2}`),
      serverRequest(ctx, true).post(`/service/user/info`, { data: { with_app: 1 } }),
    ]);

    setUserCookie(ctx, userRes);

    const data = getFreePackage(robotRes, packageRes);
    if (data) {
      const res2 = await serverRequest(ctx, true).post('/robot/package_template/purchase', {
        data,
      });
      if (res2.code !== 200) {
        throw new Error(res2.msg);
      }
    }

    const data2 = getFreePackage(robot2Res, package2Res);
    if (data2) {
      const res2 = await serverRequest(ctx, true).post('/robot/package_template/purchase', {
        data: data2,
      });
      if (res2.code !== 200) {
        throw new Error(res2.msg);
      }
    }

    return {
      redirect: {
        destination: decodeURIComponent(redirect),
        permanent: false,
      },
    };
  } catch (e) {
    Logger('合同比对开通报错', e);
  }
  return { props: {} };
}

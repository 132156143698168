export function addResourceErrorListener(handler?: (event: ErrorEvent, url?: string) => void) {
  window.addEventListener(
    'error',
    function (event: Event) {
      // 资源加载错误的特征：
      // 1. event.target 是 HTML 元素（如 <img>, <script>, <link> 等）
      // 2. event.message 通常为 undefined
      // 3. event.filename 通常为空字符串
      if (!event.target) {
        return;
      }

      const target = event.target as HTMLElement;

      let url: string | undefined;

      if (
        target instanceof HTMLImageElement ||
        target instanceof HTMLScriptElement ||
        target instanceof HTMLVideoElement ||
        target instanceof HTMLAudioElement
      ) {
        url = target.src;
      } else if (target instanceof HTMLLinkElement) {
        url = target.href;
      }

      const isResourceError = url !== undefined;

      if (isResourceError) {
        console.error('Resource failed to load:', url);
        // 处理资源加载错误，例如记录日志或通知用户
        handler?.(event as ErrorEvent, url);
      }
    },
    true,
  );
}

import { isBrowser } from '@/utils';
import { useSize } from 'ahooks';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

export const h5Routes = [
  '/user/login',
  '/user/forget_pwd',
  '/user/reset_success',
  '/terms',
  '/privacy',
  '/register/code/',
  '/register_tip',
  '/market/chager/',
  '/activity',
  '/activity/success',
];

const useGlobalStore = (props) => {
  const [isMobile, setIsMobile] = useState(props?.isMobile);
  const [isH5, setIsH5] = useState(props.isMobileRoute);
  const size = useSize(isBrowser() ? document.body : null);
  const router = useRouter();

  useEffect(() => {
    if (
      isMobile && // useAgent 包含移动设备
      size.width &&
      size.width <= 768 && // 屏幕宽度小于768px
      h5Routes.some((r) => router.asPath.startsWith(r)) // 仅开放部分页面
    ) {
      setIsH5(true);
      document.body?.classList.add('h5');
    } else {
      setIsH5(false);
      document.body?.classList.remove('h5');
    }
  }, [isMobile, size]);

  return {
    isH5,
    isMobile,
  };
};

export const GlobalStoreContainer = createContainer(useGlobalStore);
